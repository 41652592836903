import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import styles from '../../styles/info-site/OurPartners.module.css'

import Tradedeoubler from "../../assets/tradedoubler-color-logo.svg";
import Webgains from "../../assets/webgains-color.jpg";
import Partnerize from "../../assets/partnerize-color.svg";
import Awin from "../../assets/awin-black.png"
import Rakuten from "../../assets/rakouten-color.svg"
import Affiliate from "../../assets/affiliate-color.svg";
import Impact from "../../assets/impact-color.svg";

const OurPartners = ({ title, subtitle, listTitle, cards }) => {
  const logos = {
      Tradedeoubler,
      Webgains,
      Partnerize,
      Awin,
      Rakuten,
      Affiliate,
      Impact
  };
  return (
    <div className={styles.OurPartners}>
      <h2>
        <FormattedMessage id={title} />
      </h2>
      <p>
        <FormattedHTMLMessage id={subtitle} />
      </p>

      {
        listTitle && (
          <h3>
            <FormattedHTMLMessage id={listTitle} />
          </h3>
        )
      }

      <ul className={styles.OurPartnersList}>
        {
          cards.map(({ id, alt }, i) => (
            <li key={id.toLowerCase()} className={styles.OurPartnersListItem}>
              <img src={logos[id]} alt={alt} />
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default OurPartners