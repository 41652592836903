import React from "react";
import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import OurPartners from "../../components/info-site/OurPartners";
import GetStarted from "../../components/info-site/GetStarted";

import partners from '../../data/ourPartners'

const OurPartnersPage = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="ourPartnersTitle" descriptionId="ourPartnersDescription" />
            <OurPartners
              title="ourPartners"
              subtitle="ourPartnersSubtitle"
              // listTitle="ourPartnersListTitle"
              cards={partners}
            />
            <GetStarted
              title="findUsOnNetwork"
            />
        </Layout>
    );
};

export default OurPartnersPage;
