
const partners = [
  {
    id: 'Awin',
    alt: 'Awin Partner',
    logo: ''
  },
  {
    id: 'Tradedeoubler',
    alt: 'Tradedeoubler Partner',
    logo: ''
  },
  {
    id: 'Rakuten',
    alt: 'Rakuten Partner',
  },
  {
    id: 'Affiliate',
    alt: 'Affiliate Partner',
  },
  {
    id: 'Impact',
    alt: 'Impact Partner',
  },
  {
    id: 'Partnerize',
    alt: 'Partnerize Partner',
  },
  {
    id: 'Webgains',
    alt: 'Webgains Partner',
  },
];

export default partners;